


import "./BackgroundGraphics.scss";

const BackgroundGraphics = ({opacity, fillColor}) => {
  return (
    <div className="BackgroundGraphics">
      <svg viewBox="0 0 1392 871" width="100vw" height="100vh">
        <g fill="none" fillRule="evenodd"  opacity={opacity} transform="translate(0 6)">

            <g fill={fillColor} className="element_bottom_X">
                <path d="M171.4582 821 135 857.4582 142.5426 865 179 828.5418z"/>
                <path d="M142.5418 821 135 828.5418 171.4574 865 179 857.4582z"/>
            </g>

            <g fill={fillColor} strokeWidth={1} transform="translate(135 346.2379)" className="falling_line">
                <path d="M23.1251 12.163V0h-3.001v12.163h3.001Zm0 24.326V24.326h-3.001v12.163h3.001Zm0 24.326V48.652h-3.001v12.163h3.001Zm0 24.326V72.977h-3.001v12.164h3.001Zm0 24.325V97.303h-3.001v12.163h3.001Zm0 24.327V121.63h-3.001v12.163h3.001Zm0 24.327v-12.164h-3.001v12.164h3.001Zm0 24.325v-12.163h-3.001v12.163h3.001Zm0 24.326v-12.163h-3.001v12.163h3.001Zm0 24.326v-12.163h-3.001v12.163h3.001Zm0 24.326V243.26h-3.001v12.163h3.001Zm0 24.326v-12.163h-3.001v12.163h3.001Zm0 24.326v-12.164h-3.001v12.164h3.001Zm0 24.326v-12.163h-3.001v12.163h3.001Zm0 24.326v-12.163h-3.001v12.163h3.001Zm0 24.326V364.89h-3.001v12.163h3.001Zm0 24.326v-12.163h-3.001v12.163h3.001Zm0 24.326v-12.163h-3.001v12.163h3.001Zm0 24.326v-12.163h-3.001v12.163h3.001Z"/>
                <path d="m21.625 455.25 7.397-7.956H14.228z"/>
                <path d="M26.9181 7.25v-7h1.688v7h-1.688Zm0 13v-7h1.688v7h-1.688Zm0 14v-7h1.688v7h-1.688Zm0 14v-7h1.688v7h-1.688Zm0 14v-7h1.688v7h-1.688Zm0 13v-7h1.688v7h-1.688Zm0 14v-7h1.688v7h-1.688Zm0 14v-7h1.688v7h-1.688Zm0 13v-7h1.688v7h-1.688Zm0 14v-7h1.688v7h-1.688Zm0 14v-7h1.688v7h-1.688Zm0 13v-7h1.688v7h-1.688Zm0 14v-7h1.688v7h-1.688Zm0 14v-7h1.688v7h-1.688Zm0 13v-7h1.688v7h-1.688Zm0 14v-7h1.688v7h-1.688Zm0 14v-7h1.688v7h-1.688Zm0 13v-7h1.688v7h-1.688Zm0 14v-7h1.688v7h-1.688Z"/>
            </g>

            <g transform="translate(0 507)" fill={fillColor} className="M1_M2_M3">
                <path d="M462 72h-7.4788V55.843l-7.1426 12.0426h-.7549l-7.1017-12.0009V72H432V42h7.522L447 54.8134 454.5212 42H462z"/>
                <path d="M476 51v21h-2.7882V53.9998l-4.5159 1.3217L468 52.9202 473.5764 51z"/>
                <path d="M30 143h-7.478v-16.157l-7.1426 12.0426h-.7557L7.522 126.8847V143H0v-30h7.522L15 125.8134 22.522 113H30z"/>
                <path d="M51 136.5203C51 140.7027 47.6044 143 43.852 143c-3.0684 0-5.78-1.415-6.852-4.1824l2.3236-1.3548c.6261 1.9137 2.2634 2.8563 4.5284 2.8563 2.5009 0 4.3778-1.3548 4.3778-3.7988 0-2.4748-1.877-3.8296-4.3778-3.8296h-.7152l-1.104-1.679 4.8268-6.4481h-9.2654V122h12.5133v2.4147l-4.5284 6.0389c3.0076.5581 5.4209 2.6493 5.4209 6.0667"/>
                <path d="M298 286h-7.478v-16.157l-7.1426 12.0426h-.7557l-7.1017-12.0009V286H268v-30h7.522L283 268.8134 290.522 256H298z"/>
                <path d="m305 283.7629 7.1053-7.4223c1.581-1.621 2.846-3.2684 2.846-5.0068 0-2.4154-1.6955-3.6814-3.5644-3.6814-1.841 0-3.2816.9117-4.1433 2.7674L305 269.065c1.2952-2.7388 3.74-4.065 6.356-4.065 3.1361 0 6.2715 2.2401 6.2715 6.3044 0 2.5614-1.611 4.77-3.5967 6.804l-5.1476 5.2422H318V286h-13v-2.2371Z"/>
                <path d="M400 45c0 4.9702-4.0298 9-9 9s-9-4.0298-9-9 4.0298-9 9-9 9 4.0298 9 9"/>
                <path d="M18 9c0 4.9702-4.0298 9-9 9s-9-4.0298-9-9C0 4.0296 4.0298-.0003 9-.0003S18 4.0297 18 9" />
                <path d="M168 81c0 4.9702-4.0298 9-9 9s-9-4.0298-9-9c0-4.971 4.0298-9 9-9s9 4.029 9 9"/>
                <path d="M329 188c0 4.9702-4.0298 9-9 9-4.971 0-9-4.0298-9-9s4.029-9 9-9c4.9702 0 9 4.0298 9 9"/>
                <path d="M418 63c0 4.971-4.0298 9-9 9s-9-4.029-9-9c0-4.9702 4.0298-9 9-9s9 4.0298 9 9"/>
                <path d="M329.7592 269 328 267.2408l6.4065-6.4059 1.7592 1.7593L329.7592 269Zm13.445-13.445-1.7593-1.7593 7.037-7.037 1.7599 1.7593-7.0377 7.037Zm14.0752-14.0747-1.7592-1.7592 7.0369-7.0393 1.76 1.7593-7.0377 7.0392Zm14.076-14.077-1.7599-1.7592 7.0384-7.037 1.7592 1.7593-7.0376 7.037Zm14.0754-14.0761-1.7592-1.7593 7.0376-7.0377 1.76 1.7593-7.0384 7.0377Zm14.0768-14.0747-1.76-1.7592 7.0376-7.037 1.7593 1.7593-7.037 7.037Zm14.0753-14.077-1.7593-1.7592 7.0385-7.037 1.7592 1.7593-7.0384 7.037Zm14.0753-14.0746-1.7593-1.7592 7.0384-7.0393 1.7593 1.7593-7.0384 7.0392Zm14.0768-14.077-1.7593-1.7592 7.037-7.037 1.7592 1.7593-7.037 7.037Zm14.0753-14.0761-1.7593-1.7593 7.0377-7.0377 1.7584 1.7593-7.0368 7.0377Zm14.076-14.0747-1.7592-1.7592 7.0376-7.037 1.76 1.7593-7.0384 7.037Zm14.0753-14.077-1.7592-1.7592L489.2408 106 491 107.7592l-7.0384 7.037Z"/>
                <path d="m256.8877 269-8.898-4.4222 1.1123-2.2111 8.898 4.4222L256.8877 269Zm-17.796-8.8452-8.898-4.42 1.1124-2.211 8.898 4.42-1.1124 2.211Zm-17.7975-8.8421-8.898-4.4223 1.1124-2.2118 8.898 4.423-1.1124 2.211Zm-17.7959-8.8452-8.898-4.4222 1.1124-2.2111 8.898 4.4222-1.1124 2.211Zm-17.7967-8.8422-8.898-4.423 1.1124-2.211 8.898 4.423-1.1124 2.211Zm-17.796-8.8452-8.8987-4.4222 1.1116-2.211 8.8995 4.4221-1.1123 2.2111Zm-17.7974-8.8452-8.898-4.4199 1.1124-2.2111 8.8987 4.42-1.1131 2.211Zm-17.796-8.8421-8.898-4.4222 1.1124-2.2119 8.898 4.423-1.1124 2.211Zm-17.7959-8.8452-8.898-4.4222 1.1124-2.2111 8.898 4.4222-1.1124 2.2111Zm-17.796-8.8421-8.8994-4.423 1.1123-2.2111 8.8995 4.423-1.1123 2.211Zm-17.7974-8.8452-8.898-4.4222 1.1124-2.212 8.898 4.423-1.1124 2.2112Zm-17.796-8.8422-8.898-4.423 1.1124-2.211 8.898 4.4221-1.1124 2.212ZM43.331 162.873 42 162.2111 43.1123 160l1.331.661-1.1123 2.212Z"/>
                <path d="M290 233h5V131h-5z" />
                <path d="m284 141 8.5004-8.6578L301 141v-7.3476L292.5004 125 284 133.6524z" />
            </g>

            <g transform="translate(80 -120)">
                <g stroke={fillColor} className="loop_icon" >
                    <path strokeWidth={11} d="M1094.047 236.2755c65.1956 0 118.047-52.892 118.047-118.1378C1212.094 52.8921 1159.2427 0 1094.047 0 1028.8515 0 976 52.892 976 118.1377s52.8515 118.1378 118.047 118.1378Z"/>
                    <path strokeDasharray="39.961 39.961" strokeWidth={10} d="M1198.2227 239.1276 1322 363"/>          
                    <path strokeDasharray="8.033 8.033" strokeWidth={2} d="M1067 169 893 343"/>
                    <path strokeDasharray="8.033 8.033" strokeWidth={2} d="m1217 285 174 174"/>
                </g>

                <g fill={fillColor} className="arrows" >
                    <path d="m1193 234 .5465 15L1208 234.5465z"/>
                    <path d="m1070 166-6 .2187 5.7813 5.7813z"/>
                    <path d="m1214 282 .2187 6 5.7813-5.7813z"/>
                </g>
            </g>
        </g>
      </svg>
    </div>
  );
};

export default BackgroundGraphics;
