// Components
import ProductFound from "../../UI/ProductFound/ProductFound";
import WasPlayed from "../../UI/WasPlayed/WasPlayed";
import ProductRecommended from "../../UI/ProductRecommended/ProductRecommended";
import Footer from "../../Layout/Footer/Footer"

//Style for this page
import "./ResultsPage.scss";

const ResultsPage = () => {
  return (
    <div className="ResultsPage">
      <ProductFound />
      <WasPlayed />
      <ProductRecommended />
      <Footer resultsPageFooter={true}/>
    </div>
  );
};

export default ResultsPage;
