import { useContext } from "react";
import { SelectionQuestionnareContext } from "../../Context/SelectionQuestionnareContext";
import { GlobalContext } from "../../Context/GlobalContext";

import "./BackButton.scss";

const BackButton = () => {
  const { value, setValue } = useContext(GlobalContext);
  const { selected, setSelected } = useContext(SelectionQuestionnareContext);

  const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));


  const goBack = () => {
    const lastIndex = selected.pop();
    
    delay(400).then(() => {
      setSelected([...selected]);
      setValue({ ...value, question_index: lastIndex.back_id });
    })

  };

  return (
    <div
      className={`BackButton ${value.question_index === 1 ? "disabled" : ""}`}
      onClick={() => goBack()}
    >
      Go back
    </div>
  );
};

export default BackButton;
