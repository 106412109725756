//Vendor
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

// Context
import { GlobalContext } from "../../Context/GlobalContext";

//Components
import MaverikLogo from "../../UI/MaverikLogo/MaverikLogo";

//Style for this component
import "./LandingContent.scss";

const LandingContent = () => {
  
  const navigate = useNavigate();
  const { value, setValue } = useContext(GlobalContext);

  const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

  const navigateToQuestionnare = () => {
    setValue({ ...value, overlay_animation_show: true });
    delay(1000).then(() => {
      navigate("/quiz");
    });
  };

  const [landingData] = useState({
    title: "Headfinder",
    subtitle: "Discover the Perfect Head",
    description: "<p>For feeders and shooters, takeaway checks or groundball machines, <br /> we’ve designed and developed a head for every style <br /> of play in the game. </p>",
    button_text: "Let's Go",
  });

  const { title, subtitle, description, button_text } = landingData;

  const renderDescriptin = () => {
    return { __html: description };
  };

  return (
    <div className="LandingContent">
      <MaverikLogo widthValue={250}/>
      <div className="title">
        <h1>{title}</h1>
        <span className="line"></span>
      </div>
      <div className="content-inner">
        <h5>{subtitle}</h5>
        <div dangerouslySetInnerHTML={renderDescriptin()} />
        <button className="button button--default" onClick={() => navigateToQuestionnare()}>
          <span>{button_text}</span>
        </button>
      </div>
    </div>
  );
};

export default LandingContent;
