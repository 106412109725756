import { useState, useEffect } from "react";
import classNames from "classnames";
//import { GlobalContext } from "../../Context/GlobalContext";
import "./Cursor.scss";

const isMobile = () => {
  const ua = navigator.userAgent;
  return /Android|Mobi/i.test(ua);
};

const Cursor = () => {
  const [position, setPosition] = useState({ x: "50%", y: "50%" });
  const [clicked, setClicked] = useState(false);
  const [linkHovered, setLinkHovered] = useState(false);
  const [hidden, setHidden] = useState(false);

  //  const { value, setValue } = useContext(GlobalContext);

  useEffect(() => {
    addEventListeners();
    handleLinkHoverEvents();
    return () => removeEventListeners();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const addEventListeners = () => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseenter", onMouseEnter);
    document.addEventListener("mouseleave", onMouseLeave);
    document.addEventListener("mousedown", onMouseDown);
    document.addEventListener("mouseup", onMouseUp);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const removeEventListeners = () => {
    document.removeEventListener("mousemove", onMouseMove);
    document.removeEventListener("mouseenter", onMouseEnter);
    document.removeEventListener("mouseleave", onMouseLeave);
    document.removeEventListener("mousedown", onMouseDown);
    document.removeEventListener("mouseup", onMouseUp);
  };

  const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

  const onMouseMove = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };

  const onMouseDown = () => {
    setClicked(true);
  };

  const onMouseUp = () => {
    delay(600).then(() => {
      setClicked(false);
    });
  };

  const onMouseLeave = () => {
    setHidden(true);
    setLinkHovered(false);
  };

  const onMouseEnter = () => {
    setHidden(false);
    setLinkHovered(false);
  };

  const handleLinkHoverEvents = () => {
    document.querySelectorAll(".button, .BackButton").forEach((el) => {
      el.addEventListener("mouseover", () => setLinkHovered(true));
      el.addEventListener("mouseout", () => setLinkHovered(false));
    });
  };

  const cursorClasses = classNames("cursor", {
    "cursor--clicked": clicked,
    "cursor--hidden": hidden,
    "cursor--link-hovered": linkHovered,
  });

  if (typeof navigator.userAgent !== "undefined" && isMobile()) return null;

  return (
    <div
      className={cursorClasses}
      style={{
        transform: `translate(${position.x - 8}px, ${position.y - 8}px)`,
      }}
    >
      <div className="circle" />
    </div>
  );
};

export default Cursor;
