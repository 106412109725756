import { useNavigate } from "react-router-dom";

//import "./Button.scss";

const Button = (props) => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(props.url)}
      className="button button--default"
    >
      <span>{props.text}</span>
    </button>
  );
};

export default Button;
