import { useState } from "react";

import "./CostumForm.scss";

const CustomForm = ({ status, message, onValidated }) => {
  const [validation, setValidation] = useState({});

  let email, name, surname;

  const submit = (e) => {
    e.preventDefault();
    if (
      validateIsEmail(validation.email) &&
      validation.name !== "" &&
      validation.surname !== ""
    ) {
      onValidated({
        EMAIL: email.value,
        FNAME: name.value,
        LNAME: surname.value,
      });
    }
  };

  const validateIsEmail = (email) => {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
  };

  const validateInputs = (e) => {
    setValidation({
      ...validation,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="CostumForm">
      {status === "sending" && <div className="status sending">Sending...</div>}

      {status === "error" && (
        <div
          className="status error"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}

      {status === "success" && (
        <div
          className="status success"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}

      <form onSubmit={submit}>
        <div className="input-holder">
          <input
            ref={(node) => (name = node)}
            type="text"
            placeholder="First Name"
            name="name"
            onChange={(e) => validateInputs(e)}
            required
          />
          <label>
            <span>*</span>First Name
          </label>
          <span className={`check ${validation.name ? "valid" : ""}`} />
        </div>

        <div className="input-holder">
          <input
            ref={(node) => (surname = node)}
            type="text"
            placeholder="Last Name"
            name="surname"
            onChange={(e) => validateInputs(e)}
            required
          />
          <label>
            <span>*</span>Last Name
          </label>
          <span className={`check ${validation.surname ? "valid" : ""}`} />
        </div>

        <div className="input-holder">
          <input
            ref={(node) => (email = node)}
            type="email"
            placeholder="Email"
            name="email"
            onChange={(e) => validateInputs(e)}
            required
          />
          <label>
            <span>*</span>Email
          </label>
          <span
            className={`check ${
              validateIsEmail(validation.email) ? "valid" : ""
            }`}
          />
        </div>
        <button className="button button--default">
          <span>Submit</span>
        </button>
      </form>

      <span className="is-required">
        <span>*</span> REQUIRED
      </span>
    </div>
  );
};

export default CustomForm;
