//Vendor
import { useState } from "react";

// Data - questions object
import { questions } from "../../../data/data";

//Components
import ProgressBar from "../../UI/ProgressBar/ProgressBar";
import MaverikLogoQuestionnare from "../../UI/MaverikLogoQuestionnare/MaverikLogoQuestionnare";
import QuestionsLists from "../../UI/QuestionsLists/QuestionsList";
import BackButton from "../../UI/BackButton/BackButton";
import BackgroundGraphics from "../../UI/BackgroundGraphics/BackgroundGraphics";
import Footer from "../../Layout/Footer/Footer";

//Style for this page
import "./QuestionnarePage.scss";

const QuestionnarePage = () => {

  const [dataQuestions] = useState(questions);

  return (
    <div className="QuestionnarePage">
      <ProgressBar />
      <MaverikLogoQuestionnare />
      {dataQuestions.map((question) => (
        <QuestionsLists key={question.id} question={question} />
      ))}
      <BackButton />
      <BackgroundGraphics fillColor="#FEFEFE" opacity={0.07} />
      <Footer />
    </div>
  );
};

export default QuestionnarePage;
