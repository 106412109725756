import { useContext, useEffect, useState } from "react";
import { SelectionQuestionnareContext } from "../../Context/SelectionQuestionnareContext";

import "./ProgressBar.scss";

const ProgressBar = () => {
  const { selected } = useContext(SelectionQuestionnareContext);
  const [progress, setProgress] = useState();

  useEffect(() => {
    if (selected.length > 0) {
      let last = selected[selected.length - 1];
      setProgress(last.progress);
    } else if (selected.length === 0) {
      setProgress(0);
    }
  }, [selected]);

  return (
    <div className="ProgressBar">
      <div className="progress-line" style={{ width: progress + "%" }} >
        <span className="counter" >{progress}%</span>
      </div>
    </div>
  );
};

export default ProgressBar;
