
import './MadeInUSA.scss';

const MadeInUSA = ({widthValue, hasHover}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 36 40"
      width={widthValue}
      className="MadeInUSA"
    >
      <g fill="#C7C9CB" fillRule="evenodd">
      <path  className="letter-u" d="M4.149 21.8v7.818l8.292 2.991V21.8h-3.94l1.191.9v6.735l-2.663-.962V21.8z"></path>
        <path className="letter-s" d="M13.959 21.8v6.335l5.568 1.119v2.688l-1.526.667-1.472-.714-.024-1.761-2.546.681v2.317l4.042 1.594 4.143-1.546v-5.473l-5.568-.904v-3.046l2.88.024v1.937l2.688-.652V21.8z"></path>
        <path className="letter-a" d="M18.001 32.609l-1.471-.714-.024-1.761-2.547.681v2.317l4.042 1.595 4.144-1.547v-5.473l-5.568-.904v-3.045l2.879.023v1.937l2.689-.652V21.8h-8.186v6.336l5.568 1.118v2.689zM28.307 31.133l3.545-1.428V21.8H23.62v10.808l2.736-.928v-2.783l2.807-.929v1.975l-.856 1.19zm-2.07-4.33v-2.998h2.926v2.046l-2.926.951z"></path>
        <path className="flag" d="M33.897 21.8v8.752L18 36.538 2.105 30.552V21.8H0v9.913l18 8.117 18-8.117V21.8zM0 20.497h36v-1.652H0z"></path>
        <path className="flag" d="M0 10.04v7.547h36v-1.652H10.4v-1.32H36V12.97H10.4v-1.317H36V10.04H0zm4.081 4.344l-1.636-1.29h2.131l.608-1.736.61 1.736h2.13l-1.637 1.29.61 1.886-1.713-1.141-1.712 1.14.61-1.885zM33.898 21.801v8.752L18 36.538 2.105 30.553v-8.752H0v9.913l18 8.116 18-8.116v-9.913zM2.736 0l-.52 3.811-.169 1.503-.171-1.503L1.345 0H0v7.53h.87V1.809l.159 1.516.566 4.205h.757l.598-4.205.17-1.516V7.53h.938V0zM5.913 0L4.908 7.53h.903l.17-1.458h.871l.136 1.458h1.017L6.998 0H5.913zm.544 1.923l.292 3.233h-.654l.362-3.233zM17.977 7.53h.995V.002h-.995zM22.387 7.53l-1.062-3.606-.306-1.12V7.53h-.893V0h.927l.95 3.618.283 1.131V0h.881v7.53zM27.679.962V7.53h-.983V.962h-.939V.001h2.827v.961zM10.357 7.53H8.865V0h1.492c.848 0 1.38.521 1.38 1.572v4.41c0 1.062-.532 1.548-1.38 1.548zm.385-5.992c0-.407-.125-.599-.44-.599H9.86v5.664h.441c.316 0 .441-.192.441-.599V1.538zM31.433 7.53V4.296h-.95V7.53h-.995V0h.995v3.336h.95V0h.995v7.53zM12.845 7.53V0h2.408v.928h-1.425V3.2h.996v.893h-.996v2.499h1.425v.938zM33.593 7.53V0h2.408v.928h-1.425V3.2h.996v.893h-.996v2.499h1.425v.938z"></path>
      </g>
    </svg>
  );
};

export default MadeInUSA;
