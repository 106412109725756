import { createContext, useState } from "react";

export const SelectionQuestionnareContext = createContext();

export const SelectionQuestionnareProvider = ({ children }) => {

  const [selected, setSelected] = useState([]);

  return (
    <SelectionQuestionnareContext.Provider value={{ selected, setSelected }} displayName="User Selection State">
      {children}
    </SelectionQuestionnareContext.Provider>
  );
};


