import React, { useEffect, useState } from "react";

import "@google/model-viewer/dist/model-viewer";
import "./ModelView.scss";

const ModelView = ({ posterImage, gblModel }) => {
  
  const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const modelView = document.querySelector(".model-view");
    const posterImage = document.querySelector(".poster-image");

    modelView.setAttribute("src", gblModel);

    modelView.addEventListener("model-visibility", () => {        
        posterImage.style.visibility = "hidden";
        posterImage.style.opacity = 0;

        delay(800).then(() => {
          modelView.style.visibility = "visible";
          modelView.style.opacity = 1;
        });
      });

    modelView.addEventListener("preload", () => {        
        posterImage.style.visibility = "visible";
        posterImage.style.opacity = 1;
        modelView.style.visibility = "hidden";
        modelView.style.opacity = 0;
      });

    modelView.addEventListener("progress", (event) => {
      setProgress(Math.round(event.detail.totalProgress * 100))
    })
      
  }, [gblModel, posterImage]);

  return (
    <>
      <model-viewer
        class="model-view"
        src=""
        ios-src=""
        camera-orbit="45deg 90deg 1.5m"
        poster=""
        shadow-intensity="0"
        camera-controls
        auto-rotate
        disable-zoom
        loading="eager"
      >
        <div className="loading" slot="progress-bar" />
      </model-viewer>

      <div
        className="poster-image"
        slot="poster"
        style={{ backgroundImage: "url(" + posterImage + ")" }}
      />

      <div className="progress">
        <div className={`progress-inner ${progress === 100 ? 'loaded' : ''}`} style={{width: progress + "%"}}></div>
      </div>
    </>
  );
};

export default ModelView;
