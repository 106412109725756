//Vendor

import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import MailchimpSubscribe from "react-mailchimp-subscribe";

//Global State Context
import { GlobalContext } from "../../Context/GlobalContext";
import { SelectionQuestionnareContext } from "../../Context/SelectionQuestionnareContext";

//Components
import MadeInUSA from "../../UI/MadeInUSA/MadeInUSA";
import Modal from "../../UI/Modal/Modal";
import CustomForm from "../../UI/CustomForm/CustomForm";

//Style for this component
import "./Footer.scss";

const Footer = ({ landingPage, resultsPageFooter }) => {
  const { value, setValue } = useContext(GlobalContext);
  const { setSelected } = useContext(SelectionQuestionnareContext);
  const navigate = useNavigate();

  const openSubscibeModal = () => {
    setValue({ ...value, modal_show: true });
  };

  const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

  const giveAnotherTry = () => {
    setValue({ ...value, question_index: 1, overlay_animation_show: true });
    setSelected([]);

    delay(1000).then(() => {
      navigate("/");
    });
  };

  const url = "https://maveriklacrosse.us9.list-manage.com/subscribe/post-json?u=5d7485a9b810a18f85c16fee7&id=704c6e4a21&c=";


  if (resultsPageFooter) {
    return (
      <div className="Footer-Large">
        <Modal>
          <h2>
            <span>Join</span> Team Mav
          </h2>

          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                message={message}
                onValidated={(formData) => subscribe(formData)}
              />
            )}
          />
        </Modal>
        <div className="row">
          <div className="column">
            <h4>
              WANT TO GIVE IT <br /> ANOTHER TRY?
            </h4>
            <button
              className="button button--default"
              onClick={() => giveAnotherTry()}
            >
              <span>Start Over</span>
            </button>
          </div>
          <div className="column">
            <h4>
              GET EXCLUSIVE OFFERS & <br /> INPUT ON NEW GEAR
            </h4>
            <button
              className="button button--default"
              onClick={() => openSubscibeModal()}
            >
              <span>Join Team Mav</span>
            </button>
          </div>
        </div>

        <div className="background-shape" />

        <div className="copyright">
          <span className="copyright-text">
            © 2022 Maverik Lacrosse. All rights reserved.
          </span>
          <MadeInUSA widthValue={40} />
        </div>
      </div>
    );
  }

  return (
    <div className={`Footer ${landingPage ? "landing-page" : ""}`}>
      <MadeInUSA widthValue={40} />
    </div>
  );
};

export default Footer;
