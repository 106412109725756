import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { products } from "../../../data/data";

import "./WasPlayed.scss";

const WasPlayed = () => {
  let { id } = useParams();

  const [currentProduct, setCurrentProduct] = useState([]);
  const [productExists, setProductExists] = useState(false);

  useEffect(() => {
    const currentID = products.find((product) => {
      return product.id === id;
    });

    if (currentID === undefined) {
      setProductExists(false);
    } else {
      setProductExists(true);
      setCurrentProduct(currentID);
    }
  }, [currentProduct, id]);

  if (!productExists || currentProduct.player.name === "") return null;

  return (
    <section className="WasPlayed" id="player">
      <div
        className="image-thumbnail"
        style={{ backgroundImage: "url(" + currentProduct.player.image + ")" }}
      />
      <div className="content-shape" />
      <div className="content">
        <div className="inner-text">
          <span className="used-by">Used by</span>
          <span className="player-name">{currentProduct.player.name}</span>
          <span className="team">{currentProduct.player.team}</span>
        </div>
      </div>
    </section>
  );
};

export default WasPlayed;
