//Vendor
import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Data
import { products } from "../../../data/data";

//Components
import BackgroundGraphics from "../BackgroundGraphics/BackgroundGraphics";

import "./ProductRecommended.scss";

const ProductRecommended = () => {
  let { id } = useParams();
  let navigate = useNavigate();
  let productItem = useRef();

  const [relatedProducts, setRelatedProducts] = useState([]);

  const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

  const showProduct = (id) => {
    productItem.current.style.opacity = 0;
    navigate(`/results/${id}`);
    delay(400).then(() => {
      window.scrollTo(0, 0);
    });
  };

  useEffect(() => {
    const getRelatedProducts = () => {
      const getCurrentID = products.find((product) => product.id === id);

      relatedProducts.splice(0, relatedProducts.length);

      if (getCurrentID !== undefined) {
        getCurrentID.related_products_ids.map((currentID) => {
          const filterIDs = products.find(
            (product) => product.id === currentID
          );
          relatedProducts.push(filterIDs);
          setRelatedProducts([...relatedProducts]);
          return null;
        });
      }
    };

    delay(2000).then(() => {
      productItem.current.style.opacity = 1;
      getRelatedProducts();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <section className={`ProductRecommended ${!relatedProducts.length ? 'no-recommendation' : '' }`}>
      {relatedProducts.length > 0 && <h4>You may also like:</h4>}

      <div className="products-holder" ref={productItem}>
        {relatedProducts.length > 0 &&
          relatedProducts.map((product, index) => (
            <div className="product" key={index}>
              <div className="image">
                <img src={product.preview_image} alt={product.title} />
              </div>

              <div className="content">
                <div className="logo">
                  <img src={product.logo} alt={product.title} />
                </div>
                <div className="excerpt">
                  {product.excerpt}
                </div>
                <div className="button-holder">
                  <button
                    className="button button--default"
                    onClick={() => showProduct(product.id)}
                  >
                    <span>see details</span>
                  </button>
                </div>
              </div>
            </div>
          ))}
      </div>
      <BackgroundGraphics fillColor="#fff" opacity={1} />
    </section>
  );
};

export default ProductRecommended;
