import { useContext, useEffect } from "react";
import { GlobalContext } from "../../Context/GlobalContext";
import gsap from "gsap";
import "./Overlay.scss";

const Overlay = (props) => {
  const { value, setValue } = useContext(GlobalContext);

  const DOM_el = {
    svg_path: document.querySelector(".path"),
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const animateOverlayTop = () => {
    gsap
      .timeline()
      .set(DOM_el.svg_path, {
        attr: { d: "M 0 100 V 100 Q 50 100 100 100 V 100 z" },
      }).to(
        DOM_el.svg_path,
        {
          duration: 0.8,
          ease: "power4.in",
          attr: { d: "M 0 100 V 50 Q 50 0 100 50 V 100 z" },
        },
        0
      )
      .to(DOM_el.svg_path, {
        duration: 0.3,
        ease: "power2",
        attr: { d: "M 0 100 V 0 Q 50 0 100 0 V 100 z" },
      })
      .set(DOM_el.svg_path, {
        attr: { d: "M 0 0 V 100 Q 50 100 100 100 V 0 z" },
      })
      .to(DOM_el.svg_path, {
        duration: 0.3,
        ease: "power2.in",
        attr: { d: "M 0 0 V 50 Q 50 0 100 50 V 0 z" },
      })
      .to(DOM_el.svg_path, {
        duration: 0.8,
        ease: "power4",
        attr: { d: "M 0 0 V 0 Q 50 0 100 0 V 0 z" },
        onComplete: () => {
          setValue({ ...value, overlay_animation_show: false });
        },
      })
  };

  useEffect(() => {
    if (value.overlay_animation_show) {
      animateOverlayTop();
    }
  }, [animateOverlayTop, value]);

  return (
    <svg
      className="Overlay"
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
    >
      <path
        className="path"
        vectorEffect="non-scaling-stroke"
        d="M 0 100 V 100 Q 50 100 100 100 V 100 z"
        fill="#f3f3f3"
      />
    </svg>
  );
};

export default Overlay;
