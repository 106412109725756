// Import player images
import havok2_player from "../assets/images/was_played/havok2_player.jpg";
import kinetik3_player from "../assets/images/was_played/kinetik3_player.jpg";
import optik3_player from "../assets/images/was_played/optik3_player.jpg";
import tactik3_player from "../assets/images/was_played/tactik3_player.jpg";
import tank2_player from "../assets/images/was_played/tank2_player.jpg";

// Import product pocket imags
import havok2_pocket from "../assets/images/products/pocket/havok2_pocket.png";
import kinetik3_pocket from "../assets/images/products/pocket/kinetik3_pocket.png";
import lock_pocket from "../assets/images/products/pocket/lock_pocket.png";
import optik3_pocket from "../assets/images/products/pocket/optik3_pocket.png";
import tactik3_pocket from "../assets/images/products/pocket/tactik3_pocket.png";
import tank2_pocket from "../assets/images/products/pocket/tank2_pocket.png";

import tank_2_header_logo from "../assets/images/products/svg_logos/tank-2-header-logo.svg";
import tactik_3_header_logo from "../assets/images/products/svg_logos/tactik-3-header-logo.svg";
import optik_3_header_logo from "../assets/images/products/svg_logos/optik-3-header-logo.svg";
import lock_header_logo from "../assets/images/products/svg_logos/lock-header-logo.svg";
import kinetik_3_header_logo from "../assets/images/products/svg_logos/kinetik-3-header-logo.svg";
import havok2_header_logo from "../assets/images/products/svg_logos/havok-2-header-logo.svg";

// Kinetik 3
import kinetik3_glb from "../assets/images/view-model/Kinetik3.glb";
import kinetik3_image from "../assets/images/view-model/Kinetik3.png";

// Optik 3
import optik3_glb from "../assets/images/view-model/Optik3.glb";
import optik3_image from "../assets/images/view-model/Optik3.png";

// Tactik 3.0
import tactik3_glb from "../assets/images/view-model/Tactik3.glb";
import tactik3_image from "../assets/images/view-model/Tactik3.png";

// Tank 2.0
import tank2_glb from "../assets/images/view-model/Tank2.glb";
import tank2_image from "../assets/images/view-model/Tank2.png";

// Havok 2
import havok2_glb from "../assets/images/view-model/Havok2.glb";
import havok2_image from "../assets/images/view-model/Havok2.png";

// Lock
import lock_glb from "../assets/images/view-model/Lock.glb";
import lock_image from "../assets/images/view-model/Lock.png";

export const questions = [
  //Question 1
  {
    id: 1,
    question_title: "What <span class='highlight'>position</span> do you play?",
    progress: true,
    question_options: [         
      "attack",
      "middie",
      "attack/middie",
      "defense",
    ],
    answers: [
      {
        answered: "attack",
        points: [
          {
            product: "optik3",
            value: 3,
          },
          {
            product: "kinetik3",
            value: 2,
          },
          {
            product: "tactic2",
            value: 1,
          },
        ],
        next_id: 2,
        back_id: 1,
        progress: 20,
      },
      {
        answered: "middie",
        points: [
          {
            product: "tactic2",
            value: 3,
          },
          {
            product: "kinetik3",
            value: 2,
          },
          {
            product: "optik3",
            value: 1,
          },
        ],
        next_id: 2,
        back_id: 1,
        progress: 20,
      },
      {
        answered: "attack/middie",
        points: [
          {
            product: "kinetik3",
            value: 3,
          },
          {
            product: "tactic2",
            value: 2,
          },
          {
            product: "optik3",
            value: 1,
          },
        ],
        next_id: 2,
        back_id: 1,
        progress: 20,
      },
      {
        answered: "defense",
        points: 0,
        next_id: 6,
        back_id: 1,
        progress: 20,
      },
    ],
  },

  //Question 2
  {
    id: 2,
    question_title: "Style of <span class='highlight'>play?</span>",
    progress: false,
    question_options: ["feeder", "dodger", "finisher", "outside shooter"],
    answers: [
      {
        answered: "feeder",
        points: [
          {
            product: "optik3",
            value: 3,
          },
          {
            product: "kinetik3",
            value: 2,
          },
          {
            product: "tactik3",
            value: 1,
          },
        ],
        next_id: 3,
        back_id: 2,
        progress: 40,
      },
      {
        answered: "dodger",
        points: [
          {
            product: "optik3",
            value: 3,
          },
          {
            product: "tactik3",
            value: 2,
          },
          {
            product: "kinetik3",
            value: 1,
          },
        ],
        next_id: 3,
        back_id: 2,
        progress: 40,
      },
      {
        answered: "finisher",
        points: [
          {
            product: "tactik3",
            value: 3,
          },
          {
            product: "optik3",
            value: 2,
          },
          {
            product: "kinetik3",
            value: 1,
          },
        ],
        next_id: 3,
        back_id: 2,
        progress: 40,
      },
      {
        answered: "outside shooter",
        points: [
          {
            product: "kinetik3",
            value: 3,
          },
          {
            product: "tactik3",
            value: 2,
          },
          {
            product: "optik3",
            value: 1,
          },
        ],
        next_id: 3,
        back_id: 2,
        progress: 40,
      },
    ],
  },

  //Question 3
  {
    id: 3,
    question_title: "<span class='highlight'>Stat</span> sheet?",
    progress: false,
    question_options: [
      "more goals than assists",
      "more assists than goals",
      "goals and assists are even",
      "groundballs",
    ],
    answers: [
      {
        answered: "more goals than assists",
        points: [
          {
            product: "kinetik3",
            value: 3,
          },
          {
            product: "tactik3",
            value: 2,
          },
          {
            product: "optik3",
            value: 1,
          },
        ],
        next_id: 4,
        back_id: 2,
        progress: 60,
      },
      {
        answered: "more assists than goals",
        points: [
          {
            product: "optik3",
            value: 3,
          },
          {
            product: "tactik3",
            value: 2,
          },
          {
            product: "kinetik3",
            value: 1,
          },
        ],
        next_id: 4,
        back_id: 2,
        progress: 60,
      },
      {
        answered: "goals and assists are even",
        points: [
          {
            product: "optik3",
            value: 3,
          },
          {
            product: "tactik3",
            value: 2,
          },
          {
            product: "kinetik3",
            value: 1,
          },
        ],
        next_id: 4,
        back_id: 2,
        progress: 60,
      },
      {
        answered: "groundballs",
        points: [
          {
            product: "tactik3",
            value: 3,
          },
          {
            product: "kinetik3",
            value: 2,
          },
          {
            product: "optik3",
            value: 1,
          },
        ],
        next_id: 4,
        back_id: 2,
        progress: 60,
      },
    ],
  },

  //Question 4
  {
    id: 4,
    question_title:
      "Where do you spend the most <span class='highlight'>time?</span>",
    progress: false,
    question_options: ["x/behind", "crease area", "up top", "wings"],
    answers: [
      {
        answered: "x/behind",
        points: [
          {
            product: "optik3",
            value: 3,
          },
          {
            product: "kinetik3",
            value: 2,
          },
          {
            product: "tactik3",
            value: 1,
          },
        ],
        next_id: 5,
        back_id: 3,
        progress: 80,
      },
      {
        answered: "crease area",
        points: [
          {
            product: "optik3",
            value: 3,
          },
          {
            product: "tactik3",
            value: 2,
          },
          {
            product: "kinetik3",
            value: 1,
          },
        ],
        next_id: 5,
        back_id: 3,
        progress: 80,
      },
      {
        answered: "up top",
        points: [
          {
            product: "tactik3",
            value: 3,
          },
          {
            product: "kinetik3",
            value: 2,
          },
          {
            product: "optik3",
            value: 1,
          },
        ],
        next_id: 5,
        back_id: 3,
        progress: 80,
      },
      {
        answered: "wings",
        points: [
          {
            product: "kinetik3",
            value: 3,
          },
          {
            product: "optik3",
            value: 2,
          },
          {
            product: "tactik3",
            value: 1,
          },
        ],
        next_id: 5,
        back_id: 3,
        progress: 80,
      },
    ],
  },

  //Question 5
  {
    id: 5,
    question_title: "How do you <span class='highlight'>dodge?</span>",
    progress: false,
    question_options: ["speed over power", "power over speed"],
    answers: [
      {
        answered: "speed over power",
        points: [
          {
            product: "optik3",
            value: 3,
          },
          {
            product: "kinetik3",
            value: 2,
          },
          {
            product: "tactik3",
            value: 1,
          },
        ],
        next_id: "results",
        back_id: 4,
        progress: 100,
      },
      {
        answered: "power over speed",
        points: [
          {
            product: "tactik3",
            value: 3,
          },
          {
            product: "kinetik3",
            value: 2,
          },
          {
            product: "optik3",
            value: 1,
          },
        ],
        next_id: "results",
        back_id: 4,
        progress: 100,
      },
    ],
  },

  //Question 6 pt2
  {
    id: 6,
    question_title: "<span class='highlight'>stick</span> length?",
    progress: false,
    question_options: ["long stick", "short stick"],
    answers: [
      {
        answered: "long stick",
        points: 0,
        next_id: 7,
        back_id: 6,
        progress: 40,
      },
      {
        answered: "short stick",
        points: 3,
        next_id: 7,
        has_static_result: true,
        static_result: "tactik3",
        back_id: 6,
        progress: 40,
      },
    ],
  },

  //Question 7
  {
    id: 7,
    question_title: "<span class='highlight'>Position?</span>",
    active: false,
    question_options: ["close defense", "lsm", "a bit of both"],
    answers: [
      {
        answered: "close defense",
        points: [
          {
            product: "tank2",
            value: 3,
          },
          {
            product: "havok2",
            value: 2,
          },
        ],
        next_id: 8,
        back_id: 6,
        progress: 60,
      },
      {
        answered: "lsm",
        points: 0,
        has_static_result: true,
        static_result: "havok2",
        next_id: 8,
        back_id: 6,
        progress: 60,
      },
      {
        answered: "a bit of both",
        points: [
          {
            product: "havok2",
            value: 3,
          },
          {
            product: "tank2",
            value: 2,
          },
        ],
        next_id: 8,
        back_id: 6,
        progress: 60,
      },
    ],
  },

  //Question 8
  {
    id: 8,
    question_title: "<span class='highlight'>Style</span> of play?",
    active: false,
    question_options: ["takeaway/pressure", "body/close"],
    answers: [
      {
        answered: "takeaway/pressure",
        points: [
          {
            product: "havok2",
            value: 3,
          },
          {
            product: "tank2",
            value: 2,
          },
        ],
        next_id: 9,
        back_id: 7,
        progress: 80,
      },
      {
        answered: "body/close",
        points: [
          {
            product: "tank2",
            value: 3,
          },
          {
            product: "havok2",
            value: 2,
          },
        ],
        next_id: 9,
        back_id: 7,
        progress: 80,
      },
    ],
  },

  //Question 9
  {
    id: 9,
    question_title: "<span class='highlight'>Stat</span> sheet?",
    active: false,
    question_options: [
      "caused turnovers",
      "ground balls",
      "goals/assists",
      "penalty minutes",
    ],
    answers: [
      {
        answered: "caused turnovers",
        points: [
          {
            product: "havok2",
            value: 3,
          },
          {
            product: "tank2",
            value: 2,
          },
        ],
        next_id: 10,
        back_id: 8,
        progress: 90,
      },
      {
        answered: "ground balls",
        points: [
          {
            product: "tank2",
            value: 3,
          },
          {
            product: "havok2",
            value: 2,
          },
        ],
        next_id: 10,
        back_id: 8,
        progress: 90,
      },
      {
        answered: "goals/assists",
        points: [
          {
            product: "havok2",
            value: 3,
          },
          {
            product: "tank2",
            value: 2,
          },
        ],
        next_id: 10,
        back_id: 8,
        progress: 90,
      },
      {
        answered: "penalty minutes",
        points: [
          {
            product: "tank2",
            value: 3,
          },
          {
            product: "havok2",
            value: 2,
          },
        ],
        next_id: 10,
        back_id: 8,
        progress: 90,
      },
    ],
  },

  //Question 10
  {
    id: 10,
    question_title: "<span class='highlight'>Favorite</span> check?",
    active: false,
    question_options: [
      "wrap check",
      "lift check",
      "poke check",
      "slap check",
      "body check",
    ],
    answers: [
      {
        answered: "wrap check",
        points: [
          {
            product: "havok2",
            value: 3,
          },
          {
            product: "tank2",
            value: 2,
          },
        ],
        next_id: "results",
        back_id: 9,
        progress: 100,
      },
      {
        answered: "lift check",
        points: [
          {
            product: "tank2",
            value: 3,
          },
          {
            product: "havok2",
            value: 2,
          },
        ],
        next_id: "results",
        back_id: 9,
        progress: 100,
      },
      {
        answered: "poke check",
        points: [
          {
            product: "havok2",
            value: 3,
          },
          {
            product: "tank2",
            value: 2,
          },
        ],
        next_id: "results",
        back_id: 9,
        progress: 100,
      },
      {
        answered: "slap check",
        points: [
          {
            product: "tank2",
            value: 3,
          },
          {
            product: "havok2",
            value: 2,
          },
        ],
        next_id: "results",
        back_id: 9,
        progress: 100,
      },
      {
        answered: "body check",
        points: [
          {
            product: "tank2",
            value: 3,
          },
          {
            product: "havok2",
            value: 2,
          },
        ],
        next_id: "results",
        back_id: 9,
        progress: 100,
      },
    ],
  },
];

export const products = [
  {
    id: "kinetik3",
    name: "Kinetik 3",
    logo: kinetik_3_header_logo,
    optimized_for_image: kinetik3_pocket,
    short_description: "Mid-High",
    description:
      "If control, accuracy and velocity are important to you, fire at will with the Kinetik 3. One of the most trusted heads by players gets a major update with a tighter face shape for improved ball control and superior stringability. An evolved scoop enhances the angle of attack on GBs while redistributed mass results in a lighter construction and stiffer performance. Designed for the outside shooter and those who demand the responsiveness of a mid-high pocket, the Kinetik 3 continues to load up the stat sheet with high-velocity goals at every level of the game.",
    excerpt:
      "If control, accuracy and velocity are important to you, fire at will with the Kinetik 3. One of the most trusted heads by players gets a...",    
    preview_image: kinetik3_image,
    glb_preview: kinetik3_glb,
    related_products_ids: ["tactik3", "optik3"],
    player: {
      name: "Zed Williams",
      team: "PLL Whipsnakes LC",
      image: kinetik3_player,
    },
    cascadeBuyButton: {
      url: "https://cascademaverikcom/products/mens-kinetik-3-head-unstrung"
    }    
  },

  {
    id: "optik3",
    name: "Optik 3",
    logo: optik_3_header_logo,
    optimized_for_image: optik3_pocket,
    short_description: "Mid-High",
    description:
      "For the driving dodger, feeding visionary or illusive finisher, the Optik 3 combines features best suited for those quickest around the crease. An increased offset, dual-strut sidewall and optimal face shape strike a perfect balance to catch, carry and control with brilliance. Additionally for those who initiate the offense, a shortened throat rewards players with unmatched top hand control.  Now with 20 sidewall holes, the stringing options are limitless.",
    excerpt:
      "For the driving dodger, feeding visionary or illusive finisher, the Optik 3 combines features best suited for those quickest around the crease.",
    price: "$99.99",
    preview_image: optik3_image,
    glb_preview: optik3_glb,
    related_products_ids: ["tactik3", "kinetik3"],
    player: {
      name: "Mac O’Keefe",
      team: "PLL Chaos LC, Team USA",
      image: optik3_player,
    },
    retailers: [
      {
        name: "Lacrosse Unlimited",
        url: "https://www.lacrosseunlimited.com/maverik-optik-3-0-lacrosse-head.html",
      },
      {
        name: "Lax.com",
        url: "https://www.lax.com/maverik-optik-3-3.0/165622.html",
      },      
      {
        name: "Universal Lacrosse",
        url: "https://www.universallacrosse.com/maverik-optik-3-lacrosse-head.html",
      },
    ],
    cascadeBuyButton: {
      url: "https://cascademaverik.com/products/mens-optik-3-head-unstrung"
    }
  },
  {
    id: "tactik3",
    name: "Tactik 3.0",
    logo: tactik_3_header_logo,
    optimized_for_image: tactik3_pocket,
    short_description: "Mid",
    description:
      " The TACTIK 3 controls the offensive zone, and is always a threat to score or feed. Designed for the mid-range shooter and precision passer. Updated with ControlCore Technology, providing superior ball control when possessing, feeding and shooting. Featuring 20 stringing holes, the custom pocket options are endless.",
    excerpt:
      " The TACTIK 3 controls the offensive zone, and is always a threat to score or feed. Designed for the mid-range shooter and precision passer.",
    price: "$99.99",
    preview_image: tactik3_image,
    glb_preview: tactik3_glb,
    related_products_ids: ["optik3", "kinetik3"],
    player: {
      name: "Logan Wisnauskas",
      team: "PLL Chrome LC",
      image: tactik3_player,
    },
    retailers: [
      {
        name: "Lacrosse Unlimited",
        url: "https://www.lacrosseunlimited.com/tactik-3-0-lacrosse-head-unstrung.html",
      },
      {
        name: "Lax.com",
        url: "https://www.lax.com/lacrosse-equipment/maverik-tactik-3.0/175499.html",
      },      
      {
        name: "Universal Lacrosse",
        url: "https://www.universallacrosse.com/maverik-tactik-3-lacrosse-head.html",
      },
    ],
    cascadeBuyButton: {
      url: "https://cascademaverik.com/products/mens-tactik-3-head-unstrung"
    }
  },

  {
    id: "tank2",
    name: "Tank 2.0",
    logo: tank_2_header_logo,
    optimized_for_image: tank2_pocket,
    short_description: "High",
    description:
      "If you’re a fundamental defenseman built for battle, the Tank 2.0 provides the strength, stiffness and face shape to protect the house in the air, on the ground or in enemy hands. The 6-strut design, now with Xrail Technology, redefines the standards for stiffness and strength so you can lift, poke and slap with confidence. Updated with a perfectly engineered scoop, defenseman will own the up-and-out ground ball battle every time they enter the scrum. Whether your home, extended or up field, the Tank 2.0 will help you dominate the defensive end.",
    excerpt:
      "If you’re a fundamental defenseman built for battle, the Tank 2.0 provides the strength, stiffness and face shape to protect the house in the...",
    price: "$99.99",
    preview_image: tank2_image,
    glb_preview: tank2_glb,
    related_products_ids: ["havok2"],
    player: {
      name: "JT Giles-Harris",
      team: "PLL Chrome LC",
      image: tank2_player,
    },
    retailers: [
      {
        name: "Lacrosse Unlimited",
        url: "https://www.lacrosseunlimited.com/maverik-tank-2-0-lacrosse-head.html",
      },
      {
        name: "Lax.com",
        url: "https://www.lax.com/lacrosse-equipment/maverik-tank-2/148314.html",
      },      
      {
        name: "Universal Lacrosse",
        url: "https://www.universallacrosse.com/maverik-tank-2-0-lacrosse-head.html",
      },
    ],
    cascadeBuyButton: {
      url: "https://cascademaverik.com/products/mens-tank-2-head-unstrung"
    }
  },

  {
    id: "havok2",
    name: "Havok 2",
    logo: havok2_header_logo,
    optimized_for_image: havok2_pocket,
    short_description: "Mid-High",
    description:
      "The HAVOK 2 is designed for the elite playmaking LSM or Defenseman. With a tighter face shape and a lightweight 4-strut design, the HAVOK 2 offers a best-in-class strength to weight ratio to deliver precise checks and an increase in caused turnovers. Now featuring a new strut orientation, the HAVOK 2 will ensure you dominate the GB battle.",
    excerpt:
      "The HAVOK 2 is designed for the elite playmaking LSM or Defenseman. With a tighter face shape and a lightweight 4-strut design...",
    price: "$99.99",
    preview_image: havok2_image,
    glb_preview: havok2_glb,
    related_products_ids: ["tank2"],
    player: {
      name: "JT Giles-Harris",
      team: "PLL Chrome LC",
      image: havok2_player,
    },
    retailers: [
      {
        name: "Lacrosse Unlimited",
        url: "https://www.lacrosseunlimited.com/havok-2-0-lacrosse-head-unstrung.html",
      },
      {
        name: "Lax.com",
        url: "https://www.lax.com/lacrosse-equipment/maverik-havok-2/175504.html",
      },      
      {
        name: "Universal Lacrosse",
        url: "https://www.universallacrosse.com/maverik-havok-2-lacrosse-head.html",
      },
    ],
    cascadeBuyButton: {
      url: "https://cascademaveriklacrosse.com/products/mens-havok-2-head-unstrung"
    }
  },

  {
    id: "lock",
    name: "Lock",
    logo: lock_header_logo,
    optimized_for_image: lock_pocket,
    short_description: "Mid-Low",
    description:
      " The Lock is designed for the faceoff specialist. The short throat and lightweight design enables quick hand movement. FlexRail technology provides a predictive and responsive flex with each repetition. The Ground Control scoop offers an advantage securing loose balls.",
    excerpt:
      " The Lock is designed for the faceoff specialist. The short throat and lightweight design enables quick hand movement. FlexRail technology...",
    price: "$99.99",
    preview_image: lock_image,
    glb_preview: lock_glb,
    related_products_ids: [],
    player: {
      name: "",
    },
  },
];
