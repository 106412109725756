import { useContext } from "react";
import { Link } from "react-router-dom";

import Button from "../../UI/Button/Button";
import Modal from "../../UI/Modal/Modal";
import { GlobalContext } from "../../Context/GlobalContext";

import "./TypographyPage.scss";

// This component is bit messed up / but this is just for testing

const TypographyPage = () => {
  const { value, setValue } = useContext(GlobalContext);
  return (
    <div className="TypographyPage">
      <div className="buttons">
        <p>
          Button States - see footer in https://theshift.tokyo/ for mouse button
          animation
          <br />
          Button Label animation - https://www.hyperframe.com/ (see request a
          demo button)
        </p>
        <div className="buttons-inner">
          <Button url="/quiz" text="Let's go" />
          <Button url="/" text="See Landing Page" />
          <button
            className="button button--default"
            onClick={() => setValue({ ...value, modal_show: true })}
          >
            <span>Open Modal</span>
          </button>
          <Link to="/">Default Link</Link>
          <Modal>
            <h2>Join Team Maverik</h2>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nobis
            cumque ullam asperiores quisquam. Ad sint, laudantium possimus
            asperiores debitis pariatur, dolore minima dolores aut accusantium
            deserunt, fugit enim sequi excepturi.
          </Modal>

          <Link to="/" className="has-underline">
            Link Animation
          </Link>
        </div>
      </div>

      <div className="typo">
        <p>
          H Tags - Need to match letter spacing as close to possible to design
        </p>

        <h1>H1-Gilroy Black 100 pt</h1>
        <h2>H2-Gilroy Black 50 pt</h2>
        <h3>H3-Gilroy Bold 40 pt (used for price)</h3>
        <h4>H4-Gilroy Black 25 pt</h4>
        <h5>H5-Gilroy EXTRA BOLD 16 pt</h5>
        <h6>H6-Gilroy EXTRA BOLD 13 pt</h6>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo enim
          esse deleniti officia, laborum ipsam ratione fuga dolore nihil dicta
          provident iste natus magni libero dolores consequuntur! Amet, iure
          quisquam!
        </p>
      </div>
    </div>
  );
};

export default TypographyPage;
