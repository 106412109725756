import "./MaverikLogoQuestionnare.scss";

const MaverikLogoQuestionnare = () => {
  return (
    <div className="MaverikLogoQuestionnare">
      <svg
        viewBox="0 0 51 52"
      >
        <path
          fill="#fff"
          d="M25.5097 0C11.447 0 0 11.6714 0 26.0096 0 40.348 11.447 52 25.5097 52S51 40.3286 51 26.0096C51 11.6714 39.5724 0 25.5097 0m0 47.0727c-11.3899 0-20.6762-9.4494-20.6762-21.063 0-11.6137 9.267-21.0631 20.6762-21.0631 11.3899 0 20.6579 9.4494 20.6579 21.063 0 11.6137-9.268 21.063-20.6579 21.063"
        />
        <path
          fill="#fff"
          d="M20.6053 42.0817c1.607.5329 3.3254.8183 5.1184.8183 8.4257 0 15.3752-6.3946 16.4401-14.6732h-5.4733l-16.0852 13.855ZM25.7428 9.1c-9.1729 0-16.589 7.5751-16.589 16.9005 0 1.1037.1116 2.1695.2987 3.2163l10.0135-8.602c.2423-.2095.6908-.3812.9895-.3812h5.9037c.3178 0 .3741.1717.1307.3813l-15.4305 13.264c.2423.495.5229.971.8215 1.428l17.0566-14.673c.2423-.2097.6908-.3813.9895-.3813h5.9037c.3178 0 .3741.1716.1307.3812L14.8705 38.7897c.4294.3801.8588.7224 1.3264 1.0468l24.5098-21.1257C38.035 13.0398 32.3373 9.1 25.7427 9.1Zm18.7187 13.6264h-2.54l-.7854-2.4549-.7843 2.4549h-2.5411l2.0554 1.5228-.7844 2.4748 2.0544-1.5228 2.0554 1.5228-.7843-2.4748 2.0543-1.5228Z"
        />
      </svg>
      <h6>THE PERFECT HEAD FOR EVERY STYLE OF PLAY</h6>
    </div>
  );
};

export default MaverikLogoQuestionnare;
