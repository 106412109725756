import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SelectionQuestionnareContext } from "../../Context/SelectionQuestionnareContext";
import { GlobalContext } from "../../Context/GlobalContext";

import "./QuestionsList.scss";

const QuestionsLists = (props) => {
  const { question } = props;

  const navigate = useNavigate();
  const { value, setValue } = useContext(GlobalContext);  
  const { selected, setSelected } = useContext(SelectionQuestionnareContext);

  const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

  const getHighestScoreingProduct = (selected) => {
    if (!selected) return

    const productPointTotals = {} 
      
        // Loop through all selected products and calculate
        // point totals for each product
        if (selected && selected.length) {
          selected.forEach( item => {

            if (item.points && item.points.length) {
              item.points.forEach( product => {                
                const productName = product.product

                if (!productPointTotals[productName]) {                  
                  productPointTotals[productName] = product.value;
                } else {                  
                  productPointTotals[productName] += product.value;
                }
              })
            }
          })
        }

        // Loop through point totals and find product with hiest score
        if (productPointTotals) {
          let productWithHighestScore;
          let currentScoreValue = 0;

          for (const [key, value] of Object.entries(productPointTotals)) {
            if (value > currentScoreValue) {
              productWithHighestScore = key;
              currentScoreValue = value;
            }
          }  
          
          // If we find a product with the highest score, return product name
          if (productWithHighestScore) return productWithHighestScore
        }                

        // No product found, return
        return
  }

  const onSelectAnswer = (selectedObject, question) => {
    const userIsAnswered = question.answers.find(
      (ans) => ans.answered === selectedObject
    );      
    
    setSelected([...selected, userIsAnswered]);          

    if (userIsAnswered.next_id === "lock") {      
      setValue({ ...value, overlay_animation_show: true });
    
    // Clicking on a button that does not need point calculation
    } else if (userIsAnswered.has_static_result) {
      console.log( "Static", userIsAnswered.static_result )

      navigate(`/results/${userIsAnswered.static_result}`);

    // Clicking on a button that leads to another site
    } else if (userIsAnswered.has_external_result) {
      window.open(userIsAnswered.external_result, '_blank');
    
      // Clicking on a button that leads the user to the results page
      // and requires point calculation
    } else if (userIsAnswered.next_id === "results") {
      setValue({ ...value, overlay_animation_show: true });

      delay(1000).then(() => {                
        const highestScoringProduct = getHighestScoreingProduct(selected)                

        if (highestScoringProduct) {
          navigate(`/results/${highestScoringProduct}`);
        }        
      });
    } else {

      // Clicking on a button that leads to a subsequent step
      delay(800).then(() => {
        setValue({ ...value, question_index: userIsAnswered.next_id });
      });
    }
  };

  useEffect(() => {
    if (value.question_index === 1) {
      setSelected([]);
    }
  }, [setSelected, value]);

  return (
    <div
      className={`QuestionsList ${
        question.id === value.question_index ? "active" : ""
      }`}
      key={question.id}
    >
      {/* <span className="temp-dev">Debug ID: {question.id}</span> */}
      <h2 dangerouslySetInnerHTML={{ __html: question.question_title }} />

      {question.question_options.length > 0 && (
        <ul>
          {question.question_options.map((selectedObject, i) => (
            <li key={i}>
              <button
                className="button button--default"
                onClick={() => onSelectAnswer(selectedObject, question)}
              >
                <span>{selectedObject}</span>
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
export default QuestionsLists;
