//Vendor
import { Routes, Route } from "react-router-dom";

// React Context
import { GlobalContextProvider } from "./components/Context/GlobalContext";
import { SelectionQuestionnareProvider } from "./components/Context/SelectionQuestionnareContext";

// StyleSheets
import "./stylesheets/main.scss";

//Pages
import TypographyPage from "./components/Pages/TypographyPage/TypographyPage";
import LandingPage from "./components/Pages/LandingPage/LandingPage";
import QuestionnarePage from "./components/Pages/QuestionnarePage/QuestionnarePage";
import ResultsPage from "./components/Pages/ResultsPage/ResultsPage";

//Misc
import Overlay from "./components/UI/Overlay/Overlay";
import Cursor from "./components/UI/Cursor/Cursor";

const App = () => {
  return (
    <>
      <Cursor />
      <main className="App">
        <GlobalContextProvider>
          <SelectionQuestionnareProvider>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/quiz" element={<QuestionnarePage />} />
              <Route path="/results/" element={<ResultsPage />}>
                <Route path=":id" element={<ResultsPage />} />
              </Route>
              <Route path="/typography" element={<TypographyPage />} />
            </Routes>
            <Overlay />
          </SelectionQuestionnareProvider>
        </GlobalContextProvider>
      </main>
    </>
  );
};

export default App;
