import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

// Components
import MadeInUSA from "../../UI/MadeInUSA/MadeInUSA";
import BackgroundGraphics from "../../UI/BackgroundGraphics/BackgroundGraphics";
import MaverikLogo from "../../UI/MaverikLogo/MaverikLogo";
import ModelView from "../ModelView/ModelView";

import { GlobalContext } from "../../Context/GlobalContext";
import { products } from "../../../data/data";

//Style for this component
import "./ProductFound.scss";

const ProductFound = () => {
  const { value } = useContext(GlobalContext);
  const defaultProduct = products[0]; // for now getting first object on array

  let { id } = useParams();

  const [currentProduct, setCurrentProduct] = useState(defaultProduct);
  const [retailers, setRetailers] = useState(undefined);
  const [selectedRetailer, setSelectedRetailer] = useState(undefined);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getProduct = () => {
    const currentID = products.find((product) => {
      return product.id === id;
    });

    if (currentID === undefined) {
      setCurrentProduct(defaultProduct);
    } else {
      setCurrentProduct(currentID);
    }
  };

  const onRetailerSelect = (value) => {
    setSelectedRetailer(value);
  };

  useEffect(() => {
    getProduct();
  }, [getProduct, id]);

  useEffect(() => {
    if (!currentProduct) return;
    let retailers = undefined;

    if (value && value.products) {
      value.products.forEach((product) => {
        if (product.retailers && product.id === currentProduct.id) {
          retailers = product.retailers;
        }
      });
    }

    if (typeof retailers === "undefined" && currentProduct.retailers) {
      retailers = currentProduct.retailers;
    }

    setRetailers(retailers);
    setSelectedRetailer(false);
  }, [value, currentProduct]);

  const svgScrollIndicator = (
    <svg viewBox="0 0 35 48">
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#E7173E"
        transform="translate(1)"
      >
        <circle cx="16.5" cy="30.5" r="16.5" />
        <path strokeLinecap="round" d="m14.0004 28.9995 2.5 3.001 2.5-3.001" />
        <path className="svg-vertical-line" fill="none" d="M16.5 31.5V.5" />
      </g>
    </svg>
  );

  const svgDownloadShare = (
    <svg viewBox="0 0 14 17" className="download-share-icon">
      <path
        fill="#E7173E"
        fillRule="evenodd"
        d="M0 17h14v-2H0v2Zm6-9V2h2v6h1.17L7 10.17 4.83 8H6Zm8-2h-4V0H4v6H0l7 7 7-7Z"
      />
    </svg>
  );

  return (
    <section className="ProductFound">
      <MaverikLogo />
      <article className="article">
        <div className="product-preview-image">
          <MadeInUSA widthValue="90px" />
          <ModelView
            posterImage={currentProduct.preview_image}
            gblModel={currentProduct.glb_preview}
          />

          <div className="download-share">
            {svgDownloadShare}

            <a
              href={currentProduct.preview_image}
              alt="Image description"
              target="_blank"
              rel="noreferrer"
            >
              <span className="svg-label">Download & Share</span>
            </a>
          </div>
        </div>
        <div className="product-description">
          <h6>Your perfect head:</h6>
          <div className="logo">
            <img src={currentProduct.logo} alt={currentProduct.name} />
          </div>
          <div className="paragraph">
            <p>{currentProduct.description}</p>
          </div>
          <div className="product-optimized-for">
            <h5>OPTIMIZED FOR A {currentProduct.short_description} POCKET</h5>
            <img
              src={currentProduct.optimized_for_image}
              alt="optimized for a mid-low pocket"
            />
          </div>
          <div
            className={`bottom-content 
              ${retailers ? "with-retailers" : "without-retailers"}
              ${currentProduct.cascadeBuyButton && `with-cascade-buy-button`}`}
          >
            {currentProduct.price && (
              <span className="price">
                {currentProduct.price} <sub>MSRP</sub>
              </span>
            )}

            <div className={`buy-buttons`}>
              {currentProduct.cascadeBuyButton && (
                <div className="cascade-buy-button">
                  <a
                    href={currentProduct.cascadeBuyButton.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button button--buy"
                  >
                    <span>Buy Now on cascademaverik.com</span>
                  </a>
                </div>
              )}

              {currentProduct.cascadeBuyButton && retailers && (
                <div className="buy-button-divider">
                  <span className="buy-button-divider--bar" />
                  <span className="buy-button-divider--text">or</span>
                  <span className="buy-button-divider--bar" />
                </div>
              )}

              <div className="retailer-buy-buttons">
                {retailers && (
                  <>
                    <div className="button button--select">
                      <select
                        onChange={(event) =>
                          onRetailerSelect(event.target.value)
                        }
                        value={selectedRetailer}
                      >
                        <option value={false} disabled>
                          Select A Retailer
                        </option>

                        {retailers.map((retailer, index) => (
                          <option
                            key={`retailer-${index}`}
                            value={retailer.url}
                          >
                            {retailer.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    {!selectedRetailer && (
                      <button className="button button--buy button--disabled">
                        Buy Now
                      </button>
                    )}

                    {selectedRetailer && (
                      <a
                        href={selectedRetailer}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button button--buy"
                      >
                        <span>Buy Now</span>
                      </a>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </article>

      <div className="scroll-indicator">
        <a href="#player">
          <span className="scroll-text">Scroll</span>
          {svgScrollIndicator}
        </a>
      </div>

      <BackgroundGraphics fillColor="#fff" opacity={1} />
    </section>
  );
};

export default ProductFound;
