
import BackgroundGraphics from "../../UI/BackgroundGraphics/BackgroundGraphics";
import LandingContent from "../../UI/LandingContent/LandingContent";
import Footer from "../../Layout/Footer/Footer";

import "./LandingPage.scss";

const LandingPage = () => {
  return (
    <div className="LandingPage">
      <BackgroundGraphics fillColor="#fff" opacity={1}/>
      <LandingContent />
      <Footer landingPage={true}/>
    </div>
  );
};

export default LandingPage;
